
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 16 16","fill":"none"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"stroke":"currentColor","stroke-linecap":"round","stroke-width":"1.3","d":"M7.665 9.301c-.155-.067-.338-.206-.549-.417a2.6 2.6 0 010-3.677l1.768-1.768a2.6 2.6 0 013.677 3.677l-1.167 1.167m-3.06-1.584c.156.067.339.206.55.417a2.6 2.6 0 010 3.677l-1.768 1.768A2.6 2.6 0 113.44 8.884l1.167-1.167"}})])
          )
        }
      }
    